import React, { useState, useEffect } from "react";

const ApiForm = ({ api, onSubmit }) => {
    const [formValues, setFormValues] = useState({});

    // Initialize form values if there are parameters
    useEffect(() => {
        if (api.params && api.params.length > 0) {
            const defaultValues = api.params.reduce((acc, param) => {
                acc[param.name] = param.default || "";
                return acc;
            }, {});
            setFormValues(defaultValues);
        }
    }, [api.params]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(api.params && api.params.length > 0 ? formValues : {});
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="bg-white rounded-lg shadow-md p-4 mb-4"
        >
            <h3 className="text-lg font-bold mb-4">{`Parameters for ${api.name}`}</h3>

            {api.params && api.params.length > 0 ? (
                <div className="space-y-4">
                    {api.params.map((param) => (
                        <div key={param.name}>
                            <label
                                htmlFor={param.name}
                                className="block text-sm font-medium text-gray-700"
                            >
                                {param.placeholder}
                            </label>

                            {/* Render Dropdown if options are provided, else use Input */}
                            {param.options && param.options.length > 0 ? (
                                <select
                                    name={param.name}
                                    id={param.name}
                                    value={formValues[param.name] || ""}
                                    onChange={handleChange}
                                    className="input input-bordered w-full"
                                >
                                    <option value="" disabled>Select {param.placeholder}</option>
                                    {param.options.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type={param.type || "text"}
                                    name={param.name}
                                    id={param.name}
                                    value={formValues[param.name] || ""}
                                    onChange={handleChange}
                                    placeholder={param.placeholder}
                                    className="input input-bordered w-full"
                                />
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-gray-500 text-sm">
                    No parameters required for this API.
                </p>
            )}

            {/* Submit button is always visible */}
            <button type="submit" className="btn btn-primary w-full mt-4">
                Submit
            </button>
        </form>
    );
};

export default ApiForm;
