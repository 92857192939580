import React, { useState } from "react";
import ApiForm from "./ApiForm";
import MessageDisplay from "./ApiResponse";
import axios from "axios";

const ApiInterface = () => {
  const apis = [
    {
      id: 1,
      name: "Match Making Prompt Testing",
      endpoint: "https://api.smartmaheshwari.com/matchmaking_prompt_testing",
      method: "POST",
      params: [
        { name: "UserMatrimonialId", type: "text", placeholder: "Enter User Matriomonial Profile ID" },
        { name: "CandidateMatrimonialId", type: "text", placeholder: "Enter Candidate matrimonial Profile ID" },
      ],
    },
    {
      id: 2,
      name: "Tag Extraction Prompt Testing",
      endpoint: "https://api.smartmaheshwari.com/tag_extraction_prompt_testing",
      method: "POST",
      params: [
        { name: "UserId", type: "text", placeholder: "Enter User ID" },
        { name: "MatrimonialId", type: "text", placeholder: "Enter Matrimonial ID" },
      ],
    },
    {
      id: 3,
      name: "Get All Users Data",
      endpoint: "https://api.smartmaheshwari.com/get-users-testing",
      method: "GET",
      params: [],
    },
    {
      id: 4,
      name: "Get All Matrimonial Profiles Data",
      endpoint: "https://api.smartmaheshwari.com/find_matrimonial_data",
      method: "GET",
      params: [],
    },
    {
      id: 5,
      name: "Chat Extraction Prompt Testing",
      endpoint: "https://api.smartmaheshwari.com/chat_extraction_prompt_testing",
      method: "POST",
      params: [
        { name: "ChatConversation", type: "textarea", placeholder: "Paste chat data here" },
      ],
    },
  ];

  // State to track selected API
  const [selectedApi, setSelectedApi] = useState(apis[0]);

  // State to store messages for each API separately
  const [messages, setMessages] = useState(
    apis.reduce((acc, api) => {
      acc[api.id] = [];
      return acc;
    }, {})
  );

  const [loading, setLoading] = useState(false);

  const handleApiClick = (api) => {
    setSelectedApi(api);
  };


const handleSubmit = async (params) => {
  setLoading(true);

  try {
    const { method, endpoint, id } = selectedApi;
    let response;

    if (method === "GET") {
      const query = new URLSearchParams(params).toString();
      response = await axios.get(`${endpoint}?${query}`);
    } else if (method === "POST") {
      response = await axios.post(endpoint, params, {
        headers: { "Content-Type": "application/json" },
      });
    }

    const data = response.data; // Axios automatically parses JSON


    const formatJson = (jsonData) => {
      try {
        if (typeof jsonData === "string") {
          // Convert escaped JSON string into a valid JSON object first
          jsonData = JSON.parse(jsonData);
        }
        return JSON.stringify(jsonData, null, 2); // Pretty-print JSON with proper formatting
      } catch (error) {
        console.error("JSON Formatting Error:", error);
        return jsonData; // Return original data if formatting fails
      }
    };
    
    // Handle Input-Output Formatting for Specific APIs
    setMessages((prevMessages) => ({
      ...prevMessages,
      [id]:
        selectedApi.name === "Chat Extraction Prompt Testing" ||
        selectedApi.name === "Match Making Prompt Testing" ||
        selectedApi.name === "Tag Extraction Prompt Testing"
          ? [
              { 
                type: "input", 
                content: formatJson(data.data.input) // Ensures proper JSON formatting
              },
              { 
                type: "output", 
                content: formatJson(data.data.output) 
              },
            ]
          : [{ type: "full", content: formatJson(data) }],
    }));
    

    console.log("Response Data:", data);
  } catch (error) {
    let errorMessage = "An unknown error occurred.";

    if (error.response) {
      // Extracts the actual error message from the backend
      errorMessage = error.response.data?.error || JSON.stringify(error.response.data, null, 2);
    } else if (error.request) {
      // Request was made but no response received
      errorMessage = "No response from server. Please check your connection.";
    } else {
      // Generic error message
      errorMessage = error.message;
    }

    setMessages((prevMessages) => ({
      ...prevMessages,
      [selectedApi.id]: [{ type: "error", content: `Error: ${errorMessage}` }],
    }));

    console.error("Error Details:", error);
  } finally {
    setLoading(false);
  }
};


  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center mb-8">Test SMM APIs</h1>

      <div className="flex flex-col md:flex-row gap-6">
        {/* API List */}
        <div className="w-full md:w-1/3 bg-gray-100 rounded-lg shadow-md p-4">
          <h3 className="text-lg font-bold mb-4">Available APIs</h3>
          <ul>
            {apis.map((api) => (
              <li key={api.id}>
                <button
                  onClick={() => handleApiClick(api)}
                  className={`w-full text-left px-4 py-2 rounded ${
                    selectedApi?.id === api.id ? "bg-blue-100 font-semibold" : "hover:bg-gray-200"
                  } focus:outline-none focus:ring`}
                >
                  {api.name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* API Form and Messages */}
        <div className="w-full md:w-2/3">
          {selectedApi && (
            <>
              <ApiForm api={selectedApi} onSubmit={handleSubmit} />
              <MessageDisplay messages={messages[selectedApi.id]} loading={loading} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiInterface;
