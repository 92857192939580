import React from 'react';

const MessageDisplay = ({ messages, loading }) => {
  // Find input and output messages (if they exist)
  const inputMessage = messages.find(msg => msg.type === "input");
  const outputMessage = messages.find(msg => msg.type === "output");

  return (
    <div className="bg-gray-50 rounded-lg shadow-md p-4 h-64 overflow-y-auto">
      <h2 className="text-lg font-semibold mb-4">Response</h2>

      {/* Show Loading Indicator */}
      {loading && (
        <div className="flex justify-center mb-4">
          <div className="loading-spinner"></div>
        </div>
      )}

      {/* Split View for "Chat Extraction Prompt Testing" */}
      {inputMessage && outputMessage ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Input Box */}
          <div className="bg-gray-100 p-4 rounded-lg shadow-md border border-gray-300">
            <h4 className="font-semibold text-gray-700">Input</h4>
            <pre className="overflow-auto text-sm text-gray-800">{inputMessage.content}</pre>
          </div>

          {/* Output Box */}
          <div className="bg-gray-100 p-4 rounded-lg shadow-md border border-gray-300">
            <h4 className="font-semibold text-gray-700">Output</h4>
            <pre className="overflow-auto text-sm text-gray-800">{outputMessage.content}</pre>
          </div>
        </div>
      ) : (
        // Default View for Regular Responses
        messages.map((message, index) => (
          <pre
            key={index}
            className="bg-gray-100 p-4 rounded-lg overflow-x-auto text-sm text-gray-800"
          >
            {message.content}
          </pre>
        ))
      )}

      {/* No Messages Display */}
      {!loading && messages.length === 0 && (
        <p className="text-gray-500 text-sm text-center">No messages to display.</p>
      )}
    </div>
  );
};

export default MessageDisplay;
