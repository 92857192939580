import React, { useState } from 'react';

const AccessCodeScreen = ({ onAccessGranted }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const validateCode = () => {
    // Replace with your custom logic
    const validCode = '98765'; // Example code
    return code === validCode;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateCode()) {
      setError('');
      onAccessGranted();
    } else {
      setError('Invalid access code. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div className="bg-white p-6 rounded shadow-md w-96">
        <h2 className="text-lg font-bold text-center mb-4">Enter Access Code</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="password"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter 5-digit code"
            maxLength="5"
            className="input input-bordered w-full"
          />
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button type="submit" className="btn btn-primary w-full">
            Generate Api Request
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccessCodeScreen;
